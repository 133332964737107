import React from "react";
import AllProducts from "../../components/Products/AllProducts";

const Shop = () => {
  return (
    <div>
      <AllProducts />
    </div>
  );
};

export default Shop;
